import React, { useEffect, useRef, useState } from 'react';

const Pyramid = () => {
    const images = [
        '/brain.png',
        '/mountains.jpg',
        '/thought.jpg',
        '/camera.png',
        '/bicycle.png',
    ];

    const pyramidRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    // Use IntersectionObserver to detect if the component is in view
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                setIsVisible(entry.isIntersecting);
            },
            { threshold: 0.2 } // Trigger when 50% of the component is in view
        );
        if (pyramidRef.current) {
            observer.observe(pyramidRef.current);
        }

        return () => {
            if (pyramidRef.current) {
                observer.unobserve(pyramidRef.current);
            }
        };
    }, []);

    return (
        <div ref={pyramidRef} className="relative w-full h-[300px] md:h-[400px] flex items-end justify-center">
            {/* Row 1 - Top layer, one card */}
            <div className="absolute z-30">
                <div className="w-40 h-64 md:w-64 md:h-80 bg-white rounded-lg shadow-2xl py-4 border border-black border-opacity-10">
                    <img src={images[0]} alt="brain" className="w-full h-[20vh] object-cover mt-4 md:mt-8" />
                    <p className='text-xs md:text-sm font-inter px-2'>Daily Life</p>
                    <p className='text-sm md:text-md font-inter font-semibold px-2'>6 Steps to Good Decision Making</p>
                </div>
            </div>

            {/* Row 2 - Two cards (initially behind the first card) */}
            <div className={`absolute z-20 top-16 md:top-24 flex gap-x-0 justify-center transition-all duration-1000 ease-out ${isVisible ? 'translate-x-0 opacity-100' : 'translate-x-[0] opacity-0'}`}>
                <div className={`w-38 h-52 md:w-52 md:h-72 bg-white rounded-lg shadow-2xl transition-transform duration-1000 ease-out ${isVisible ? '-translate-x-[1px] md:-translate-x-[40px]' : 'translate-x-[0]'}`}>
                    <img src={images[1]} alt="mountains" className="w-full h-[20vh] object-cover rounded-t-lg" />
                    <p className='text-sm font-inter px-2'>Kanema Travel</p>
                    <p className='text-md font-inter font-semibold px-2'>Lilongwe</p>
                </div>
                <div className={`w-38 h-52 md:w-52 md:h-72 bg-white flex flex-col items-center justify-start rounded-lg shadow-2xl transition-transform duration-1000 ease-out ${isVisible ? 'translate-x-[1px] md:translate-x-[40px]' : 'translate-x-[0]'}`}>
                    <img src={images[2]} alt="bicycle" className="w-full h-[20vh] object-cover rounded-t-lg" />
                    <p className='text-sm font-inter px-2'>Health</p>
                    <p className='text-md font-inter font-semibold px-2'>Healthy Living</p>
                </div>
            </div>

            {/* Row 3 - */}
            <div className={`absolute z-10 top-20 md:top-28 flex items-center justify-center transition-all duration-1000 ease-out ${isVisible ? 'translate-x-0 opacity-100' : 'translate-x-[0] opacity-0'}`}>
                <div className={`w-32 h-48 md:w-48 md:h-64 flex flex-col items-start justify-start bg-white rounded-lg shadow-2xl transition-transform duration-1000 ease-out ${isVisible ? '-translate-x-[50px] md:-translate-x-[120px]' : 'translate-x-[0]'}`}>
                    <img src={images[3]} alt="camera" className="w-full h-[20vh] object-cover rounded-t-lg" />
                    <p className='text-sm font-inter px-2'>Kanema Online</p>
                    <p className='text-md font-inter font-semibold px-2'>Street Photography</p>
                </div>
                <div className={`w-32 h-48 md:w-48 md:h-64 bg-white flex flex-col items-center justify-start rounded-lg shadow-2xl transition-transform duration-1000 ease-out ${isVisible ? 'translate-x-[50px] md:translate-x-[120px]' : 'translate-x-[0]'}`}>
                    <img src={images[4]} alt="thought" className="w-full h-[18vh] object-cover rounded-t-lg" />
                    <p className='text-md font-inter font-semibold px-2'>Love Cycling</p>
                </div>
            </div>
        </div>
    );
};

export default Pyramid;
