import { React } from 'react';
import Navbar from '../components/navbar';
import Welcome from '../components/welcome';
import Carousel from '../components/carousel';
import ImageCarousel from '../components/image_carousel';
import Features from '../components/features';
import BeInspired from '../components/be_inspired';
import Footer from '../components/footer';
const Home = () => {
    return (
        <>
        <div className='w-full md:w-[1200px] mx-auto'>
            <Navbar />
            <Welcome />
            {/* <Carousel /> */}
            <ImageCarousel />
            <Features />
            <BeInspired />
        </div>

        <div className='w-full bg-white'>
            <Footer />
        </div>

        </>
    )
}

export default Home