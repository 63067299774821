import { React } from 'react';
import download from '../assets/logos.png'

const Welcome = () => {
    return (
        <div className='flex flex-col justify-center py-6'>
            <h1 className='text-black text-4xl md:text-6xl font-inter font-bold md:font-semibold text-center mx-8 md:mx-40'>A Gateway to Millions of News, Stories & Blogs</h1>

            <div className='bg-black text-white text-lg rounded-full w-fit py-3 px-8 mx-auto my-4 cursor-pointer border hover:bg-transparent hover:border-black hover:text-black hover:scale-105'>Get this App</div>

            <div className='flex justify-center items-center gap-x-4 mx-auto'>
                <p className='text-lg text-black text-opacity-50'>App Available For</p>

                    <img src={download} alt="Logo" className="w-24 h-24 object-contain" />
            </div>
        </div>
    )
}

export default Welcome;