import {React} from 'react';
import Pyramid from './Inspired/pyramid';

const BeInspired = () => {
    return (
        <div className='w-full md:w-[80%] flex flex-col items-center justify-center mx-auto md:my-12'>

            <h1 className='text-4xl text-center font-inter font-bold'>Be Inspired</h1>
            <p className='text-md md:text-xl text-black text-opacity-50 text-center md:w-[55vh] md:mt-4'>
                Explore Thousands of stories and blogs from your favorite writers, both local and international.
            </p>

            <Pyramid />

        </div>
    )
}

export default BeInspired