import React, { useState } from 'react';
import { MdArrowBackIos } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { motion, AnimatePresence } from 'framer-motion';

const ImageCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(2); // Start with the middle image
  const [direction, setDirection] = useState(0); // Track direction (1 for next, -1 for previous)

  const images = [
    "/read_page.png",
    "/home_screen.png",
    "/splash_screen.png",
    "/stories.png",
    "/blogs.png",
  ];

  // Handle Next Click
  const handleNext = () => {
    setDirection(1); // Set direction as forward
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  // Handle Previous Click
  const handlePrev = () => {
    setDirection(-1); // Set direction as backward
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  // Define styles for images based on index
  const getImageStyle = (index) => {
    switch (index) {
      case 0:
        return { height: '18rem' }; // Far left
      case 1:
        return { height: '23rem' }; // Left
      case 2:
        return { height: '26rem' }; // Center
      case 3:
        return { height: '23rem' }; // Right
      case 4:
        return { height: '18rem' }; // Far right
      default:
        return { height: '5rem' }; // Default
    }
  };

  // Get the correct index for the image based on the current position
  const rotatedImages = [
    images[(currentIndex - 2 + images.length) % images.length], // Far left
    images[(currentIndex - 1 + images.length) % images.length], // Left
    images[currentIndex], // Center
    images[(currentIndex + 1) % images.length], // Right
    images[(currentIndex + 2) % images.length], // Far right
  ];

  // Animation variants for sliding left or right
  const variants = {
    enter: (direction) => {
      return {
        x: direction > 0 ? 400 : -400, // Start outside the view depending on direction
        opacity: 0,
      };
    },
    center: {
      x: 0, // Center the image
      opacity: 1,
    },
    exit: (direction) => {
      return {
        x: direction > 0 ? -300 : 300, // Exit the view depending on direction
        opacity: 0,
      };
    },
  };

  return (
    <div className="relative w-full flex justify-center items-center overflow-hidden">
      {/* Left Arrow */}
      <button
        onClick={handlePrev}
        className="absolute left-16 md:left-96 text-red text-3xl z-10 bg-white p-1 rounded-full hover:scale-105"
      >
        <MdArrowBackIos />
      </button>

      <div className="images-wrapper flex justify-center items-center gap-x-3 overflow-hidden py-4">
        <AnimatePresence initial={false} custom={direction}>
          {rotatedImages.map((image, index) => (
            <motion.img
              key={image}
              src={image}
              alt={`Carousel ${index}`}
              style={getImageStyle(index)}
              className="w-auto h-fit rounded-3xl"
              custom={direction} // Pass direction to animation
              variants={variants} // Define enter, center, and exit animations
              initial="enter"
              animate="center"
              exit="exit"
              transition={{ duration: 0.9 }} // Adjust the timing
            />
          ))}
        </AnimatePresence>
      </div>

      {/* Right Arrow */}
      <button
        onClick={handleNext}
        className="absolute right-16 md:right-96 text-red text-3xl z-10 bg-white p-1 rounded-full hover:scale-105"
      >
        <IoIosArrowForward />
      </button>
    </div>
  );
};

export default ImageCarousel;
