import {React} from 'react'

const PersonalizedNews = () => {
    return (
        <div className='w-full md:w-[80%] flex flex-col md:flex-row items-center justify-center gap-x-12 mx-auto my-12'>
            <div className='md:w-[50%] flex-flex-col justify-start items-center px-1 md:px-0'>
                <h1 className='text-xl font-inter font-bold text-center md:text-left'>Bringing You Personalised News</h1>
                <p className='text-md md:text-lg text-black text-opacity-50 text-center md:text-left'>
                    Stay informed with instant updates from local, national and global sources. Get personalized content tailored to your interests. With real-time alerts and customizable features. Effortlessly browse trusted news stories from around the world, anytime, anywhere.
                </p>
            </div>

            <div className='w-full md:w-[40%]'>
                <img src="/Personalized.png" alt="Logo" className="w-[75%] h-auto rounded-3xl object-contain mx-auto mt-4 md:mt-0" />
            </div>

        </div>
    )
}

export default PersonalizedNews