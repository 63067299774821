import { React } from 'react';
import { MdOutlineEmail } from "react-icons/md";
import { FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { PiInstagramLogoFill } from "react-icons/pi";

const Footer = () => {
    return (
        <div className='w-full flex flex-col items-center justify-center'>

            <hr className="border border-black my-4 w-full" />

            <div className='w-[80%] flex flex-col items-center justify-center my-4 md:my-8'>
                <div className='w-40 h-auto my-4 md:my-8'>
                    <img src="/nkhani-logo.png" alt="Logo" className="w-full h-auto object-contain" />
                </div>

                <h1 className="text-lg font-inter font-semibold my-3 md:my-6">A World of News</h1>

                <div className='flex items-center justify-center gap-x-3 bg-black rounded-xl py-4 px-6 text-white my-3 md:my-6'>
                    <MdOutlineEmail size={24} />
                    <p className="text-white text-md-font-inter">hello@nkhani.com</p>
                </div>

                <nav className='my-2 md:my-4'>
                    <ul className='flex items-center justify-center gap-x-4 text-md md:text-lg text-black text-opacity-40'>
                        <li><a href="#">Features</a></li>
                        <li><a href="#">FAQ</a></li>
                        <li><a href="#">Contact</a></li>

                    </ul>
                </nav>

                <hr className="border border-black my-4 w-full" />

                <nav className='my-4'>
                    <ul className='flex items-center justify-center gap-x-4 text-lg text-black text-white'>
                        <li><a href="#">
                            <div className='p-3 bg-black rounded-lg '>
                                <FaLinkedin size={28} />
                            </div>
                        </a></li>
                        <li><a href="#">
                            <div className='p-3 bg-black rounded-lg'>
                                <FaXTwitter size={28} />
                            </div>
                        </a></li>
                        <li><a href="#">
                            <div className='p-3 bg-black rounded-lg'>
                                <PiInstagramLogoFill size={28} />
                            </div>
                        </a></li>

                    </ul>
                </nav>

            </div>

        </div>
    )
}

export default Footer