import {React} from 'react';
import PersonalizedNews from './features/personalized_news';
import FeatureRow from './features/feature_row';

const Features = () => {
    return (
        <div className='flex flex-col justify-center mt-8 md:mt-16 mx-auto'>
            <h1 className='text-2xl md:text-4xl text-center font-inter font-bold md:w-[55vh] mx-auto'>a local and global news source, tailored to you</h1>
            <p className='text-md md:text-xl text-center text-black text-opacity-50 mt-2 md:mt-4 w-full md:w-[80%] mx-auto'>
                Discover an expansive selection of blogs and news articles, personalized just for you.
                From local voices to international perspectives. Stay informed with thousands of stories 
                from your favorite writers across the globe. Dive into the latest updates, trends and insights 
                that matter most to you.
            </p>

            <PersonalizedNews />
            <FeatureRow />
        </div>
    )
}

export default Features;