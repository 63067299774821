import { React } from 'react'

const FeatureRow = () => {
    return (
        <div className='w-full md:w-[80%] flex flex-col md:flex-row items-start justify-center my-12 mx-auto'>
            <div className="w-full md:w-[50%] flex flex-col items-center justify-center">
                <h1 className='text-xl font-inter font-bold text-center w-full md:w-[40vh]'>Keeping you in the loop with all the action that matters to you</h1>
                <p className='text-md md:text-lg text-black text-opacity-50 text-center w-[40vh]'>Stay up-to-date with the latest action and news, delivered fast and tailored to your interests</p>

                <img src="/loop.png" alt="Logo" className="w-[60%] h-auto rounded-t-3xl object-contain" />

            </div>

            <div className="w-full md:w-[50%] flex flex-col items-center justify-center mt-8 md:mt-0">
                <h1 className='text-xl font-inter font-bold text-center w-full md:w-[40vh]'>The feed that delivers the news you care about</h1>
                <p className='text-md md:text-lg text-black text-opacity-50 text-center w-[40vh]'>Instant access to breaking news as it happens, both locally and globally</p>

                <img src="/loop.png" alt="Logo" className="w-[60%] h-auto rounded-t-3xl object-contain mt-4 md:mt-7" />

            </div>
        </div>
    )
}

export default FeatureRow